import { EntryPoint } from '@/components/account';
import CommonIcon from '@/components/common-icon';
import { AffiliateHeader } from '@/components/header/affiliate-header';
import LogoIcon from '@/components/header/components/icon/logo-icon';
import Image from '@/components/image';
import { BasicModal } from '@/components/modal';
import { Desktop } from '@/components/responsive';
import { getCommonInviteCodeInfoApi } from '@/core/api';
import { useRequestData, useRouter } from '@/core/hooks';
import { LANG, renderLangContent } from '@/core/i18n';
import { formatToMonthDayTimeRange, getVipCodeParamValue, MediaInfo } from '@/core/utils';
import dayjs from 'dayjs';
import { useState } from 'react';
import { BAR_4IMG_MAP, BAR_5IMG_MAP, VOLUME_HEIGHT } from '../constants';

export default function VipCodeRegister() {
  const router = useRouter();
  const vipCode = getVipCodeParamValue('vipCode');
  const ru = router.query?.ru;
  const [resInfo, , , isLoading] = useRequestData(getCommonInviteCodeInfoApi, {
    params: vipCode || ru,
    enableIsLoading: true,
  });
  const [showRulesModal, setShowRulesModal] = useState(false);
  const type = resInfo?.note?.type;
  const isFanNote = !!resInfo?.fanNote;

  // 默认左侧banner内容
  const renderDefaultLeftBannerContent = () => {
    return (
      <div className='banner-content'>
        <p className='title'>
          {renderLangContent('Join {username} on BYDFi Today and Win Welcome Bonus', {
            username: resInfo?.username || '',
          })}
        </p>
        <p className='high-light'>{'Up to $8,100'}</p>
      </div>
    );
  };
  const renderBarContent = () => {
    const rules = resInfo?.note?.rules;
    if (!rules || !rules.length) return null;
    return (
      <div className='bar-container'>
        {rules.map((item, index) => {
          const getBarImg = () => {
            if (rules.length === 4) {
              return BAR_4IMG_MAP[item.level || index + 1];
            }
            return BAR_5IMG_MAP[item.level || index + 1];
          };
          return (
            <div className='bar-column' key={item.trading}>
              <p className='bonus'>{item.bonus}</p>
              <p className='tips'>{LANG('Future Bonus')}</p>
              <Image
                src={getBarImg()}
                width={48}
                height={VOLUME_HEIGHT[item.level || index + 1]}
                className='bar-icon'
              />
              <div className='bottom-column'>
                <p className='label'>{LANG('Deposit')}</p>
                <p className='value'>{'>' + item.deposit}</p>
                <p className='label'>{LANG('Volume')}</p>
                <p className='value'>{'>' + item.trading}</p>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  // 首次交易送活动/首次充值送活动/充值交易量活动
  const renderBannerContentByType = () => {
    const activeTime = resInfo?.note?.activeTime || 0;
    const expireTime = resInfo?.note?.expireTime || 0;
    const isValid = !!activeTime && !!expireTime;
    return (
      <div className='banner-content'>
        <p className='title'>{resInfo?.note?.title}</p>
        <p className='high-light'>{resInfo?.note?.subtitle}</p>
        {isValid && (
          <p className='sub-title'>
            {formatToMonthDayTimeRange(resInfo?.note?.activeTime || 0, resInfo?.note?.expireTime || 0)}
          </p>
        )}
        {renderBarContent()}
      </div>
    );
  };

  const onRuleClick = () => {
    setShowRulesModal(true);
  };
  const BANNER_MAP: any = {
    DEPOSIT_TRADING_ADMOUNT: '/static/images/register/deposit-trading-amount.svg',
    FIRST_DEPOSIT: '/static/images/register/get-cashback.svg',
    FIRST_TRADING: '/static/images/register/get-usdt.svg',
  };

  return (
    <>
      <AffiliateHeader />
      <div className='affiliate-register'>
        <div className='register-container'>
          <Desktop>
            <div className='left-card'>
              <div className='header-area'>
                <LogoIcon width='104' height='25' />
                <CommonIcon name='common-activity-close-0' size={16} style={{ margin: '0 12px' }} />
                <Image
                  src={resInfo?.avatar || '/static/images/account/user.png'}
                  width={32}
                  height={32}
                  className='avatar'
                />
                <span className='name'>{resInfo?.username}</span>
              </div>

              {isLoading ? null : isFanNote ? (
                // 粉丝活动
                <div className='action-box'>
                  <p className='rules' onClick={onRuleClick} style={{ cursor: 'pointer' }}>
                    {LANG('Rules and Terms') + '  >>'}
                  </p>

                  <p className='rules'>{`${dayjs(resInfo?.fanNote?.activeTime).format('MMM D[th] HH:mm')} - ${dayjs(
                    resInfo?.fanNote?.expireTime
                  ).format('MMM D[th] HH:mm')}`}</p>
                </div>
              ) : type ? (
                // 新人活动
                renderBannerContentByType()
              ) : (
                renderDefaultLeftBannerContent()
              )}
            </div>
          </Desktop>
          <div className='right-card'>
            <EntryPoint />
          </div>
        </div>
        {showRulesModal && (
          <BasicModal
            title={LANG('Rules and  Terms')}
            open={showRulesModal}
            onCancel={() => setShowRulesModal(false)}
            cancelButtonProps={{ style: { display: 'none' } }}
            okText={LANG('确定')}
            onOk={() => setShowRulesModal(false)}
          >
            {!isFanNote && <p className='title'>{LANG('Rules and Terms:')}</p>}
            {isFanNote ? (
              <p style={{ whiteSpace: 'pre-wrap' }}>{resInfo?.fanNote?.note}</p>
            ) : (
              resInfo?.note?.notes.map((item) => {
                return <p key={item}>{item}</p>;
              })
            )}
          </BasicModal>
        )}
        <style jsx>{`
          .affiliate-register {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            min-height: calc(100vh - 64px);
            background-color: var(--spec-background-color-1);
            @media ${MediaInfo.mobileOrTablet} {
              background-color: var(--spec-background-color-2);
              align-items: flex-start;
            }
            .register-container {
              position: relative;
              min-height: 610px;
              height: auto;
              width: 920px;
              margin-top: 40px;
              margin-bottom: 126px;
              display: flex;
              align-items: stretch;
              @media ${MediaInfo.mobileOrTablet} {
                width: 100%;
                margin-top: 0px;
              }
              .left-card {
                position: relative;
                width: 460px;
                height: auto;
                min-height: 610px;
                padding: 24px 16px;
                border-top-left-radius: 8px;
                background: url(${isLoading
                    ? ''
                    : isFanNote
                    ? resInfo?.fanNote?.logo
                    : type
                    ? BANNER_MAP[type]
                    : '/static/images/register/bonus-banner.svg'})
                  center center no-repeat;
                background-size: cover;
                .header-area {
                  z-index: 2;
                  position: relative;
                  display: flex;
                  align-items: center;
                  color: var(--spec-font-color-1);
                  .name {
                    margin-left: 8px;
                    margin-right: 12px;
                    font-size: 18px;
                    color: var(--spec-font-color-1);
                    font-weight: 500;
                  }
                  :global(.avatar) {
                    border-radius: 50%;
                  }
                }
                :global(.banner-content) {
                  position: relative;
                  margin-top: 32px;
                  height: 83%;
                  :global(.title) {
                    font-size: 20px;
                    color: var(--spec-font-color-1);
                    font-weight: 500;
                  }
                  :global(.high-light) {
                    font-size: 44px;
                    font-weight: 700;
                    color: var(--skin-primary-color);
                  }
                  :global(.sub-title) {
                    margin-top: 12px;
                    font-size: 20px;
                    font-weight: 500;
                    color: var(--spec-font-color-1);
                  }
                  :global(.bar-container) {
                    display: flex;
                    align-items: flex-end;
                    width: 100%;
                    margin-top: 42px;
                    position: absolute;
                    bottom: 30px;
                    :global(.bar-column) {
                      display: flex;
                      flex: 1;
                      flex-direction: column;
                      align-items: center;
                      text-align: center;
                      :global(.bonus) {
                        color: var(--spec-brand-font-color);
                        font-size: 24px;
                        font-weight: 700;
                      }
                      :global(.tips) {
                        font-size: 12px;
                        color: var(--spec-font-color-2);
                      }
                      :global(.bar-icon) {
                        margin-top: 4px;
                        margin-bottom: 10px;
                      }
                      :global(.label) {
                        font-size: 12px;
                        color: var(--spec-font-color-1);
                      }
                      :global(.value) {
                        font-size: 12px;
                        color: var(--spec-brand-font-color);
                        font-weight: 500;
                      }
                      :global(.bottom-column) {
                        padding: 0 8px;
                        word-break: break-all;
                      }
                    }
                  }
                }
                .action-box {
                  position: absolute;
                  bottom: 24px;
                  left: 16px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: calc(100% - 32px);
                  .rules {
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--spec-font-color-1);
                  }
                }
              }
              .right-card {
                background-color: var(--spec-background-color-2);
                height: 100%;
                min-height: 680px;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                @media ${MediaInfo.mobileOrTablet} {
                  width: 100%;
                }
              }
            }
          }
          :global(.basic-content) {
            color: var(--spec-font-color-1);
            font-size: 16px;
            font-weight: 500;
            :global(.title) {
              font-weight: 600;
            }
          }
        `}</style>
      </div>
    </>
  );
}
